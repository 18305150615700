import { Link } from "react-router-dom";
import {
  Box,
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Select,
  Stack,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import MeuEditor from "../Components/Editor/CKeditor";

import api from "../services/api";
import { useState, useContext, useEffect, useRef } from "react";
import { MateriaContext } from "../contexts/Materia";
import { AiOutlineScissor } from "react-icons/ai";
import { RiScissorsCutFill } from "react-icons/ri";
import TinyMCEEditor from "../Components/Editor/TinyMCEEditor";
import { BookContext } from "../contexts/Book";

const QuestionMateryList = ({ matery }) => {
  const { createNotebookApi, createAnswerQuestionApi } =
    useContext(BookContext);
  const {
    listQuestoesApi,
    listMateriaApi,
    listMateriaQuestoesApi,
    DetailMateriaApi,
    listSubjectsApi,
    listAllMateriaQuestoesApi,
  } = useContext(MateriaContext);
  const materyID = localStorage.getItem("matery_id");
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isAnswerCorrect, setIsAnswerCorrect] = useState({});
  const [questions, setQuestions] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [user, setUser] = useState();
  const [isAnswerCut, setIsAnswerCut] = useState({});
  const [scissorClickedMap, setScissorClickedMap] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [editorContent, setEditorContent] = useState("");
  const [editorOpenMap, setEditorOpenMap] = useState({});
  const [difficultyID, setDifficultyID] = useState(0);
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState("null");
  const [answeredFilter, setAnsweredFilter] = useState();
  const editorRef = useRef(null);

  const openLoginModal = () => {
    onOpen();
  };

  const handleListQuestion = (e) => {
    setAnsweredFilter(e);
    handleSelectMateria(difficultyID, subject, e);
  };
  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };
    try {
      const response = await api.post("auth/login", data);

      const loggedUser = response.data.user;
      const token = response.data.token;

      localStorage.setItem("user", JSON.stringify(loggedUser));
      localStorage.setItem("token", token);

      setUser(loggedUser);
      setIsLoggedIn(true);
      if (response.status === 200) {
        window.location.reload();
        setIsLoggedIn(true);
        onClose();
      }
      return response;
    } catch (error) {
      JSON.stringify(error);
      console.log(error.data);
      window.location.reload();
      return error;
    }
  };
  const handleUpdateContent = (newContent) => {
    console.log(newContent);
    setEditorContent(newContent);
  };
  const handleCutAnswer = (questionId) => {
    // Verifica se a resposta já foi cortada
    const isAlreadyCut = isAnswerCut[questionId] || false;

    // Atualiza o estado isAnswerCut para indicar se a resposta foi cortada ou não
    setIsAnswerCut((prevIsAnswerCut) => ({
      ...prevIsAnswerCut,
      [questionId]: !isAlreadyCut,
    }));

    // Lógica adicional para alterar o estado do botão, texto ou outros comportamentos
    const newSelectedAnswers = { ...selectedAnswers };
    if (isAlreadyCut) {
      delete newSelectedAnswers[questionId];
    }
    setSelectedAnswers(newSelectedAnswers);
  };
  async function handleSelectMateria(difficulty, subject, answerList) {
    const materiaID = localStorage.getItem("matery_id");
    const subject2 = subject;
    const answered = answerList;
    try {
      const materiaInfoApi = await listAllMateriaQuestoesApi(
        materiaID,
        difficulty,
        subject,
        answered
      );
      setQuestions(materiaInfoApi.data?.questions);
    } catch (error) {
      console.log(error);
    }
  }

  const handleChangeSubject = (value) => {
    setSubject(value);
    if (value > 0) {
      handleSelectMateria(difficultyID, value);
    } else {
      handleSelectMateria(difficultyID, null);
    }
  };

  async function getAssuntosMateria() {
    const materiaID = localStorage.getItem("matery_id");
    try {
      const subjectsApi = await listSubjectsApi(materiaID);
      console.log(subjectsApi.data);
      setSubjects(subjectsApi.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }
  function userLogged() {
    const user = JSON.parse(localStorage.getItem("user")); // Converte o JSON para objeto
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    console.log(user);
    if (!user) {
      setIsLoggedIn(false);
      window.location.reload(); // Se o usuário não estiver logado, exiba o modal de login
    } else {
      api
        .get(`/user/${user.id}/status`, { headers })
        .then((response) => {
          setIsLoggedIn(true);
        })
        .catch((error) => {
          setIsLoggedIn(false);
          if (error.response && error.response.status === 401) {
            setIsLoggedIn(false); // Exiba o modal de login se ocorrer um erro de autenticação
          } else {
            setIsLoggedIn(true);
            window.location.reload();
          }
        });
    }
  }

  useEffect(() => {
    userLogged();

    handleSelectMateria(null, null);
    getAssuntosMateria();
  }, [materyID]);

  const handleChangeDifficulty = (value) => {
    setSubject(null);
    handleSelectMateria(value);
    setDifficultyID(value);

    if (value > 0) {
      handleSelectMateria(value, null);
    } else {
      handleSelectMateria(null, null);
    }
  };

  const handleAnswerSelection = (questionId, answerId) => {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionId]: answerId,
    }));
  };

  const handleAnswerSubmission = async (questionId) => {
    // Obtém a resposta selecionada pelo usuário
    const selectedAnswer = selectedAnswers[questionId];
    if (!isLoggedIn) {
      // Se o usuário não estiver logado, abrir o modal de login
      openLoginModal();
      return;
    }
    // Verifica se a resposta foi selecionada
    if (!selectedAnswer) {
      return;
    }

    // Obtém a pergunta correspondente ao ID
    const question = questions.find((q) => q.id === questionId); // Corrija esta linha

    if (!question) {
      return;
    }

    const data = {
      question_id: questionId,
      answer_id: selectedAnswer,
    };
    try {
      const response = await createAnswerQuestionApi(data);
      console.log(response.data);
      if (response.status === 200) {
        const correctAnswerIndex = question.answers.findIndex(
          (answer) => answer.answer_verify === 1
        );

        const isCorrect =
          question.answers[correctAnswerIndex].id === selectedAnswer;

        setIsAnswerCorrect((prevIsAnswerCorrect) => ({
          ...prevIsAnswerCorrect,
          [questionId]: isCorrect,
        }));

        toast({
          title: "Resposta enviada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    handleSelectMateria(null, null);
  };

  const handleNotebookSave = async (value) => {
    console.log(value);
    if (!isLoggedIn) {
      // Se o usuário não estiver logado, abrir o modal de login
      openLoginModal();
      return;
    } else {
      try {
        const userInfo = JSON.parse(localStorage.getItem("user"));
        const data = {
          question_id: value.id,
          user_id: userInfo.id,
          notebook: editorContent,
        };
        const response = await createNotebookApi(data);
        if (response.status == 200) {
          toast({
            title: "Caderno Atualizado com Sucesso",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }

        handleSelectMateria(difficultyID);
      } catch (error) {
        if (error.response.status == 401) {
          toast({
            title: "Sua sessão expirou, faça o login novamente",
            status: "error",
            isClosable: true,
          });
          openLoginModal();
          return;
        }
      }
    }
  };

  return (
    <>
      <Flex justifyContent={"center"}>
        <Box borderRadius="md" p={4} width={"700px"} color={"white"}>
          <Box>
            <Text mb={2}>Filtrar por dificuldade:</Text>
            <Select
              placeholder="Selecione a dificuldade"
              required
              onChange={(e) => handleChangeDifficulty(e.target.value)}
            >
              <option color={"black"} value="1">
                Iniciante
              </option>
              <option value="2">Intermediário</option>
              <option value="3">Avançado</option>
            </Select>
          </Box>

          <Text mt={5}>Filtrar por assunto</Text>
          <Select
            mb={5}
            required
            value={subject || 0}
            placeholder="Selecionar assunto"
            onChange={(e) => handleChangeSubject(e.target.value)}
          >
            {subjects &&
              subjects.map((index) => (
                <>
                  <option key={index.id} value={index.id}>
                    {index.subject}
                  </option>
                </>
              ))}
          </Select>

          {difficultyID != 0 ? (
            <RadioGroup
              defaultValue="all"
              onChange={(e) => handleListQuestion(e)}
            >
              <Stack direction="row" color={"#050F40"}>
                <Radio value="all">Todas</Radio>
                <Radio value="true">Respondidas</Radio>
                <Radio value="false">Não Respondidas</Radio>
              </Stack>
            </RadioGroup>
          ) : (
            " "
          )}
        </Box>
      </Flex>
      <Box>
        {questions?.map((question, index) => {
          const correctAnswerIndex = question.answers.findIndex(
            (answer) => answer.answer_verify === 1
          );

          const questionId = question?.id;

          return (
            <>
              <Flex justifyContent="center">
                <Box key={index} my={2} width={"90vw"}>
                  <Box
                    borderWidth="1px"
                    borderRadius="md"
                    p={4}
                    width={"100%"}
                    bg={"white"}
                    color={"black"}
                  >
                    <Text fontWeight="bold">Questão {index + 1}:</Text>
                    <Box mt={1} mb={5}>
                      <Box fontWeight={"bold"} fontSize={"14px"} pr={2}>
                        <Text> Materia: {matery}</Text>
                      </Box>
                      <Box fontWeight={"bold"} fontSize={"14px"}>
                        <Text>Dificuldade: {question.difficulty_name}</Text>
                      </Box>
                      <Box>
                        {question.subject != null ? (
                          <Text fontSize={"14px"} fontWeight={"bold"}>
                            Assunto: {question.subject?.subject}
                          </Text>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>

                    <Box
                      fontSize={"18px"}
                      dangerouslySetInnerHTML={{
                        __html: question.question,
                      }}
                    />

                    {question?.answers.map((answer, index) => {
                      const isAnswerSelected =
                        selectedAnswers[question.id] === answer.id;
                      const isMyAnswer =
                        question.my_answer &&
                        answer.id === question.my_answer.answer_id;

                      return (
                        <Box
                          bg={"white"}
                          key={answer.id}
                          display="flex"
                          alignItems="center"
                          mt={2}
                          color={"black"}
                          cursor="pointer"
                        >
                          <Box
                            ml={2}
                            as={
                              scissorClickedMap[answer.id]
                                ? RiScissorsCutFill
                                : AiOutlineScissor
                            }
                            fontSize={"20px"}
                            cursor="pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setScissorClickedMap((prevMap) => ({
                                ...prevMap,
                                [answer.id]: !prevMap[answer.id], // Invert the clicked state
                              }));
                              handleCutAnswer(answer.id);
                            }}
                            __css={{
                              animation: "scissorsAnimation 0.1s ease-in-out",
                              "@keyframes scissorsAnimation": {
                                "0%": {
                                  transform: "scale(1)",
                                },
                                "50%": {
                                  transform: "scale(0)",
                                },
                                "100%": {
                                  transform: "scale(1)",
                                },
                              },
                            }}
                          />
                          <Button
                            ml={2}
                            p={"0px"}
                            fontSize={"14px"}
                            borderRadius={"18px"}
                            isDisabled={
                              isAnswerCut[answer.id] ||
                              (question.my_answer && isMyAnswer)
                            }
                            color={isAnswerSelected ? "gray" : "Black"}
                            variant={isAnswerSelected ? "solid" : "outline"}
                            colorScheme={isAnswerSelected ? "gray" : "gray"}
                            mr={2}
                            onClick={() =>
                              handleAnswerSelection(question.id, answer.id)
                            }
                            css={{
                              textDecoration: scissorClickedMap[answer.id]
                                ? "line-through"
                                : "none",
                            }}
                          >
                            {String.fromCharCode(65 + index)}
                          </Button>
                          <Box
                            ml={2}
                            flex="1"
                            css={{
                              textDecoration: scissorClickedMap[answer.id]
                                ? "line-through"
                                : "none",
                              color: scissorClickedMap[answer.id]
                                ? "#ccc"
                                : "black",
                            }}
                            dangerouslySetInnerHTML={{ __html: answer.answer }}
                          />
                        </Box>
                      );
                    })}
                    <Box
                      display="inline-flex"
                      justifyContent="center"
                      mt={4}
                      mb={5}
                    >
                      <Button
                        mr={5}
                        bgColor={"#FAB929"}
                        onClick={() => {
                          setEditorOpenMap((prevEditorOpenMap) => ({
                            ...prevEditorOpenMap,
                            [question.id]: !prevEditorOpenMap[question.id], // Invert the open state
                          }));
                        }}
                      >
                        {!editorOpenMap[question.id]
                          ? "Adicionar Anotação"
                          : "Fechar Editor"}
                      </Button>
                      <Button
                        bgColor={"#FAB929"}
                        onClick={() => {
                          handleAnswerSubmission(question.id);
                          userLogged();
                        }}
                        isDisabled={!selectedAnswers[question.id]}
                      >
                        Enviar Resposta
                      </Button>
                    </Box>
                    {question.my_answer != null &&
                      question?.my_answer?.answer === 1 && (
                        <Box display="flex" justifyContent="center" mt={2}>
                          <Text color="green">Resposta Correta!</Text>
                        </Box>
                      )}
                    {question.my_answer != null &&
                      question?.my_answer?.answer === 0 && (
                        <Box display="flex" justifyContent="center" mt={2}>
                          <Text color="red">
                            Resposta incorreta. A resposta correta é:{" "}
                            {String.fromCharCode(65 + correctAnswerIndex)}
                          </Text>
                        </Box>
                      )}
                    <Flex justifyContent="center" flexDirection="column">
                      {/* Resto do seu código da pergunta */}
                      {editorOpenMap[question.id] && (
                        <>
                          <MeuEditor
                            ref={editorRef}
                            initialValue={question.notebook?.book}
                            onChange={(newContent) =>
                              handleUpdateContent(newContent)
                            }
                          />
                          <Button
                            mt={2}
                            onClick={() => handleNotebookSave(question)}
                          >
                            {" "}
                            Salvar{" "}
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Box>
                </Box>
              </Flex>
            </>
          );
        })}

        {/* Modal de login */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Login</ModalHeader>
            <ModalBody>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Button mt={5} onClick={handleLogin}>
                {" "}
                Login{" "}
              </Button>
            </ModalBody>
            <Box maxW={"350px"}>
              <Stack
                ml={5}
                pb={2}
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Text>Ainda não tem Cadastro?</Text>

                <Link to={"/cadastro"}>
                  <Text color={"#FAB929"} fontWeight={"bold"}>
                    Cadastre-se
                  </Text>
                </Link>
              </Stack>
            </Box>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default QuestionMateryList;
