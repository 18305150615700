import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Grid,
  VStack,
  useToast,
  useDisclosure,
  Input,
  Select,
  Flex,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import { MateriaContext } from "../../contexts/Materia";
import MeuEditor from "../../Components/Editor/CKeditor";
const MateriaPage = () => {
  const toast = useToast();
  const {
    DetailMateriaApi,
    createRespostaQuestaoApi,
    deleteRespostaQuestoesApi,
    createQuestaoApi,
    deleteQuestionApi,
    updateQuestionApi,
    createSubjectApi,
    listSubjectsApi,
    deleteSubjectApi,
  } = useContext(MateriaContext);
  const [materia, setMateria] = useState();
  const [isQuestionEditing, setIsQuestionEditing] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState("");
  const {
    isOpen: isAnswerModalOpen,
    onOpen: onAnswerModalOpen,
    onClose: onAnswerModalClose,
  } = useDisclosure();
  const {
    isOpen: isQuestionModalOpen,
    onOpen: onQuestionModalOpen,
    onClose: onQuestionModalClose,
  } = useDisclosure();

  const [newAnswer, setNewAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [newQuestion, setNewQuestion] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuestionBoxOpen, setIsQuestionBoxOpen] = useState(false);
  const [isSubjectBoxOpen, setIsSubjectBoxOpen] = useState(false);
  const [questionID, setQuestionID] = useState();
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState();
  const [subjectQuestion, setSubjectQuestion] = useState();
  const [updateSubject, setUpdateSubject] = useState();

  const timeoutRef = useRef(null);

  async function getMateriaInfo() {
    const materiaID = localStorage.getItem("matery_id");
    try {
      const materiaInfoApi = await DetailMateriaApi(materiaID);
      console.log(materiaInfoApi.data);
      setMateria(materiaInfoApi.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getAssuntosMateria() {
    const materiaID = localStorage.getItem("matery_id");
    try {
      const subjectsApi = await listSubjectsApi(materiaID);
      console.log(subjectsApi.data);
      setSubjects(subjectsApi.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMateriaInfo();
    getAssuntosMateria();
  }, []);

  const handleAddAnswer = async () => {
    if (!selectedQuestion) return;

    // Verificar se já existe uma resposta correta
    const hasCorrectAnswer = selectedQuestion.answers.some(
      (answer) => answer.answer_verify === 1
    );

    if (isCorrect && hasCorrectAnswer) {
      // Exibir mensagem de erro ao usuário
      alert("Já existe uma resposta correta para essa questão!");
      return;
    }

    const newAnswerObj = {
      question_id: selectedQuestion.id,
      answer: newAnswer,
      is_correct: isCorrect ? 1 : 0,
    };

    // Chamar a função para adicionar a resposta via API ou atualizar o estado
    try {
      const answerInfoApi = await createRespostaQuestaoApi(newAnswerObj);
      console.log(answerInfoApi);

      if (answerInfoApi.status === 200) {
        toast({
          title: "Resposta adicionada com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
      // Exibir mensagem de erro ao usuário, se necessário
    }

    setNewAnswer("");
    setIsCorrect(false);
    setSelectedQuestion(null);
    onAnswerModalClose();
    getMateriaInfo();
  };

  const deleteResposta = async (id) => {
    try {
      const deleteResposta = await deleteRespostaQuestoesApi(id);
      console.log(deleteResposta.data);
      if (deleteResposta.status === 200) {
        toast({
          title: "Resposta deletada com Sucesso",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getMateriaInfo();
  };

  const handleChangeDifficulty = (value) => {
    setDifficulty(value);
  };

  const handleSubjectQuestion = (value) => {
    setSubjectQuestion(value);
  };

  const handleAddQuestion = async () => {
    const materiaID = localStorage.getItem("matery_id");
    const data = {
      matery_id: materiaID,
      difficulty: difficulty,
      question: newQuestion,
      subject_id: subjectQuestion,
    };
    try {
      const createQuestion = await createQuestaoApi(data);
      console.log(createQuestion.data);
      if (createQuestion.status === 200) {
        toast({
          title: "Questão adicionada com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    // Reset the newQuestion state after submitting
    setNewQuestion("");
    setIsQuestionBoxOpen(false);
    getMateriaInfo();
    handleSubjectQuestion(null);
  };

  const handleAddSubject = async () => {
    const materiaID = localStorage.getItem("matery_id");
    const data = {
      matery_id: materiaID,
      subject: subject,
    };
    try {
      const response = await createSubjectApi(data);
      console.log(response.data);
      if (response.status === 200) {
        toast({
          title: "Assunto adicionada com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    // Reset the newQuestion state after submitting
    setSubject("");
    setIsSubjectBoxOpen(false);
    getAssuntosMateria();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChangeNewQuestion = (value) => {
    setNewQuestion(value);
    // Atraso de 800 milissegundos (ajuste conforme necessário)
  };

  const handleChangeNewAnswer = (value) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setNewAnswer(value);
    }, 800); // Atraso de 800 milissegundos (ajuste conforme necessário)
  };
  // Função para lidar com a alteração no JoditEditor
  const handleChange = (value) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setEditedQuestion(value);
    }, 800); // Atraso de 800 milissegundos (ajuste conforme necessário)
  };

  const handleUpdateQuestion = async () => {
    const data = {
      question: editedQuestion,
      subject_id: updateSubject,
    };

    try {
      const updateQuestrion = await updateQuestionApi(questionID, data);
      console.log(updateQuestrion);

      if (updateQuestrion.status === 200) {
        toast({
          title: "Questão atualizada com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
      // Exibir mensagem de erro ao usuário, se necessário
    }
    getMateriaInfo();
    closeModal();
  };

  const deleteQuestion = async (id) => {
    try {
      const deleteQuestion = await deleteQuestionApi(id);
      console.log(deleteQuestion.data);
      if (deleteQuestion.status === 200) {
        toast({
          title: "Questão deletada com Sucesso",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getMateriaInfo();
  };

  const deleteSubject = async (id) => {
    try {
      const deleteSubject = await deleteSubjectApi(id);
      console.log(deleteSubject.data);
      if (deleteSubject.status === 200) {
        toast({
          title: "Assunto deletada com Sucesso",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getAssuntosMateria();
  };

  return (
    <>
      <Navbar />
      {materia && (
        <Box
          p={8}
          ml={{ base: 0, md: 60 }}
          bgColor={"#f0f8ff"}
          minH={"100vh"}
          mt={"-35px"}
        >
          <Flex justifyContent="space-between">
            <Heading as="h1" size="xl" mb={4}>
              {materia.matery?.matery}
            </Heading>
            <Box>
              <Button
                colorScheme="teal"
                mr={3}
                onClick={() => setIsQuestionBoxOpen(true)}
              >
                Adicionar nova questão
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => setIsSubjectBoxOpen(true)}
              >
                Adicionar Assunto
              </Button>
            </Box>
          </Flex>

          {subjects && (
            <Box my={5}>
              <Text>Lista de assuntos da matéria</Text>
              <HStack spacing={4}>
                {subjects.map((index) => (
                  <Tag borderRadius="full" variant="solid" colorScheme="green">
                    <TagLabel>{index.subject}</TagLabel>
                    <TagCloseButton onClick={() => deleteSubject(index.id)} />
                  </Tag>
                ))}
              </HStack>
            </Box>
          )}

          {isQuestionBoxOpen && (
            <Box
              borderWidth="1px"
              borderRadius="md"
              p={4}
              bg="white"
              boxShadow="md"
              mb={5}
            >
              <Select
                my={5}
                placeholder="Selecione a dificuldade"
                required
                onChange={(e) => handleChangeDifficulty(e.target.value)}
              >
                <option value="1">Iniciante</option>
                <option value="2">Intermediário</option>
                <option value="3">Avançado</option>
              </Select>

              <Text>Selecione o assunto da questão</Text>
              <Select
                mb={5}
                required
                value={subjectQuestion}
                placeholder="Selecionar assunto"
                onChange={(e) => handleSubjectQuestion(e.target.value)}
              >
                {subjects &&
                  subjects.map((index) => (
                    <option value={index.id}>{index.subject}</option>
                  ))}
              </Select>
              <MeuEditor
                my={5}
                value={newQuestion ?? " "}
                onChange={(e) => {
                  handleChangeNewQuestion(e);
                }}
                // Configurações do TinyMCE
              />
              <Button colorScheme="teal" onClick={handleAddQuestion} mt={5}>
                Salvar Questão
              </Button>
              <Button
                ml={2}
                colorScheme="red"
                onClick={() => setIsQuestionBoxOpen(false)}
                mt={5}
              >
                Cancelar
              </Button>
            </Box>
          )}

          {isSubjectBoxOpen && (
            <Box
              borderWidth="1px"
              borderRadius="md"
              p={4}
              bg="white"
              boxShadow="md"
              mb={5}
            >
              <FormControl>
                <FormLabel>Digite o Assunto</FormLabel>
                <Input onChange={(e) => setSubject(e.target.value)} />
              </FormControl>
              <Button colorScheme="teal" onClick={handleAddSubject} mt={5}>
                Salvar Assunto
              </Button>
              <Button
                ml={2}
                colorScheme="red"
                onClick={() => setIsSubjectBoxOpen(false)}
                mt={5}
              >
                Cancelar
              </Button>
            </Box>
          )}
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap={4}
          >
            {materia.matery?.questions?.map((question) => (
              <Box
                key={question.id}
                borderWidth="1px"
                borderRadius="md"
                p={4}
                bg="white"
                boxShadow="md"
              >
                <Text fontSize={"18px"} fontWeight={"bold"} py={5}>
                  Dificuldade:
                  {question.difficulty === 1
                    ? " Iniciante"
                    : question.difficulty === 2
                    ? " Intermediário"
                    : question.difficulty === 3
                    ? " Avançado"
                    : ""}
                </Text>
                {question.subject != null ? (
                  <Text fontSize={"14px"} fontWeight={"bold"} py={5}>
                    Assunto: {question.subject?.subject}
                  </Text>
                ) : (
                  ""
                )}

                <Box fontSize="md" mb={2}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: isQuestionEditing
                        ? editedQuestion
                        : question.question,
                    }}
                  />
                </Box>
                {question.answers.map((answer, index) => (
                  <Box
                    key={answer.id}
                    p={2}
                    borderBottom={"1px solid #e9e9e9"}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display={"inline-flex"}>
                      <Text fontWeight={"bold"}>
                        ({String.fromCharCode(65 + index)})
                      </Text>
                      <Box ml={5}>
                        <span
                          ml={5}
                          dangerouslySetInnerHTML={{
                            __html: answer.answer,
                          }}
                        />
                      </Box>
                    </Box>

                    {answer.answer_verify === 1 ? (
                      <Text color="green.500">Resposta correta</Text>
                    ) : (
                      <Text color="red.500">Resposta incorreta</Text>
                    )}
                    <Button
                      size="sm"
                      mt={3}
                      colorScheme="red"
                      onClick={() => deleteResposta(answer.id)}
                    >
                      Excluir
                    </Button>
                  </Box>
                ))}
                <Center>
                  <Button
                    mt={4}
                    colorScheme="teal"
                    onClick={() => {
                      setSelectedQuestion(question);
                      onAnswerModalOpen();
                    }}
                  >
                    Adicionar Resposta
                  </Button>
                  <Button
                    mt={4}
                    ml={5}
                    onClick={() => {
                      openModal();
                      setEditedQuestion(question.question);
                      setQuestionID(question.id);
                    }}
                  >
                    Editar Questão
                  </Button>
                  <Button
                    mt={4}
                    ml={5}
                    colorScheme="red"
                    onClick={() => deleteQuestion(question.id)}
                  >
                    Excluir Questão
                  </Button>
                </Center>
                <Modal isOpen={isAnswerModalOpen} onClose={onAnswerModalClose}>
                  <ModalOverlay />
                  <ModalContent width={"1000px"} maxW={"90%"}>
                    <ModalHeader>Adicionar Resposta</ModalHeader>
                    <ModalBody>
                      <VStack spacing={4} align="stretch">
                        <Text>Resposta:</Text>
                        <MeuEditor
                          value={newAnswer ?? " "}
                          onChange={(value) => handleChangeNewAnswer(value)}
                        />
                        <Text>Verdadeiro ou Falso:</Text>
                        <Select
                          value={isCorrect ? "true" : "false"}
                          onChange={(e) =>
                            setIsCorrect(e.target.value === "true")
                          }
                        >
                          <option value="true">Verdadeiro</option>
                          <option value="false">Falso</option>
                        </Select>
                      </VStack>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        colorScheme="teal"
                        mr={3}
                        onClick={handleAddAnswer}
                      >
                        Adicionar
                      </Button>
                      <Button onClick={() => onAnswerModalClose()}>
                        Cancelar
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </Box>
            ))}
          </Grid>
        </Box>
      )}

      {/** Modal criar questao */}

      <Modal isOpen={isQuestionModalOpen} onClose={onQuestionModalClose}>
        <ModalOverlay />
        <ModalContent width={"900px"} maxW={"90%"}>
          <ModalHeader>Adicionar Questão</ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Select
                placeholder="Selecione a dificuldade"
                required
                onChange={(e) => handleChangeDifficulty(e.target.value)}
              >
                <option value="1">Iniciante</option>
                <option value="2">Intermediário</option>
                <option value="3">Avançado</option>
              </Select>
              <Text>Questão:</Text>
              <MeuEditor
                value={newQuestion ?? " "}
                onChange={(value) => handleChangeNewQuestion(value)}
                spellCheck={false}
                cursorOffSet={50}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddQuestion}>
              Adicionar
            </Button>
            <Button onClick={() => onQuestionModalClose()}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent width={"1000px"} maxW={"90%"}>
          <ModalHeader>Editar Questão</ModalHeader>
          <ModalBody>
            <Text>Selecione o assunto da questão</Text>
            <Select
              mb={5}
              required
              placeholder="Selecionar assunto"
              onChange={(e) => setUpdateSubject(e.target.value)}
            >
              {subjects &&
                subjects.map((index) => (
                  <option value={index.id}>{index.subject}</option>
                ))}
            </Select>
            <MeuEditor
              initialValue={editedQuestion}
              value={editedQuestion}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleUpdateQuestion}>
              Atualizar
            </Button>
            <Button onClick={closeModal}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MateriaPage;
